import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TermsContent from "../components/content/terms"
import Nav from "../components/nav"
import Footer from "../components/footer"

const TermsPage = () => (
  <>
    <SEO title="Terms" />
    <Nav />
    <TermsContent />
    <Footer />
  </>
)

export default TermsPage
