import React, {useState} from "react"
import { Link } from "gatsby"
import AppContext from "./appcontext"
import PlaceHelper from "./placehelper"

{/* TODO:
https://getbootstrap.com/docs/4.0/components/navbar/
*/}
{/*<nav className="navbar navbar-light bg-light static-top">
  <div className="container">
    <Link className="navbar-brand" to="/">POC</Link>
	<Link className="nav-link js-scroll-trigger" to="/about">About</Link>
	<Link className="nav-link js-scroll-trigger" to="/contact">Contact</Link>

    <Link className="btn btn-primary" to="/">Demo</Link>
  </div>
</nav>*/}



const Nav = () => {

	const placeHeloper = PlaceHelper();

	// odd but capitilizing below function ChangePlace fixes compile error
	const ChangePlace = (place) => {
		//this.setState({ mssg: "Hi there!" });
		//const placeHeloper = PlaceHelper();
		const currentPlace = placeHeloper.getCurrentPlaceData();
		console.log("after click", currentPlace)
		let [placeData, setPlaceData] = useState({ ...currentPlace })
	};

return (

<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
	<div className="container">

		<AppContext.Consumer>
		{ ({placeData, setPlaceData}) =>
			<Link className="navbar-brand" to="/">
				{placeData.fullPlaceName}
			</Link> 
		}
		</AppContext.Consumer>
		
		<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span className="navbar-toggler-icon"></span>
		</button>

		<div className="collapse navbar-collapse" id="navbarSupportedContent">
			<ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-4">
			  <li className="nav-item">
			    <Link className="nav-link" to="/augmented-reality-apps">Augmented Reality</Link> 
			  </li>
			  {/* <li className="nav-item">
			    <Link className="nav-link" to="/solutions">Solutions</Link> 
			  </li> */}
			  <li className="nav-item">
			    <Link className="nav-link" to="/about">About</Link> 
			  </li>
			
			  <li className="nav-item dropdown">
			    <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			      Change a Place
			    </a> 
			    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
			      <Link className="dropdown-item" to="/?place=new-york" onClick={() => ChangePlace("new-york")}>New York City</Link> 
			      <div className="dropdown-divider"></div>
			      <Link className="dropdown-item" to="/?place=central-park-nyc" onClick={() => ChangePlace("ceentral-park-nyc")}>Central Park</Link> 
			      <div className="dropdown-divider"></div>
			      <Link className="dropdown-item" to="/?place=los-angeles"onClick={() => ChangePlace("los-angeles")}>Los Angeles</Link> 
				</div>
			  </li>
			  {/*
			  <li className="nav-item">
			    <Link className="nav-link disabled" to="#">Disabled</Link> 
			  </li>
			  */}
			</ul>
			<AppContext.Consumer>
			{ ({placeData, setPlaceData}) =>
				<a className="btn btn-primary" target="_blank" href={placeData.appStoreLink}>Download an App</a>
			}
			</AppContext.Consumer>
			{/*
			<form className="form-inline my-2 my-lg-0">
			  <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
			  <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
			</form>
			*/}
		</div>
  </div>
</nav>



)}

export default Nav
