import React from "react"
import { Link } from "gatsby"

import { FaTwitter,FaFacebookF,FaInstagram } from 'react-icons/fa'


const Footer = () => (


	
	
	<>
	{/* Call to Action */ }	
		<section className="call-to-action text-white text-center">
			{/* <div className="overlay"></div> */}
			<div className="container">
				<div className="row">
				<div className="col-xl-9 mx-auto">
					<h2 className="mb-4">Explore all Augmented Reality apps!</h2>
				</div>
				<div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
					<form>
					<div className="form-row d-flex justify-content-center">
						{/* <div className="col-12 col-md-9 mb-2 mb-md-0">
						<input type="email" className="form-control form-control-lg" placeholder="Enter your email..." />
						</div> */}
						
						<a target="_blank" className="btn btn-primary" 
						 href="https://apps.apple.com/us/developer/vadym-ustymenko/id402027552">
							Go to AppStore!
						</a>
						
					</div>
					</form>
				</div>
				</div>
			</div>
		</section>
	
		<footer className="footer bg-light">

			<div className="container">
				<div className="row">
				<div className="col-lg-6 h-100 text-center text-lg-left my-auto">
					<ul className="list-inline mb-2">
					<li className="list-inline-item">
						{/*<a href="#">About</a>*/}
						<Link to="/about">About</Link>
					</li>
					<li className="list-inline-item">&sdot;</li>
					<li className="list-inline-item">
						<Link to="/contact">Contact</Link>
					</li>
					{/* <li className="list-inline-item">&sdot;</li>
					<li className="list-inline-item">
						<Link to="/terms">Terms of Use</Link>
					</li>
					<li className="list-inline-item">&sdot;</li>
					<li className="list-inline-item">
						<Link to="/privacy">Privacy Policy</Link>
					</li> */}
					</ul>
					<p className="text-muted small mb-4 mb-lg-0">&copy; World in Catalog {new Date().getFullYear()}. All Rights Reserved.</p>
				</div>
				<div className="col-lg-6 h-100 text-center text-lg-right my-auto">
					<ul className="list-inline mb-0">
					{/* <li className="list-inline-item mr-3">
						<a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com"><FaFacebookF /></a>
					</li>
					<li className="list-inline-item">
						<a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com"><FaInstagram /></a>
					</li> */}
					<li className="list-inline-item mr-3">
						<a rel="noopener noreferrer" target="_blank" href="https://twitter.com/VunetApps"><FaTwitter /></a>
					</li>
					</ul>
				</div>
				</div>
			</div>
			</footer>

	</>



)

export default Footer
