import React from "react"
import { Link } from "gatsby"

const TermsContent = () => (

	<div className="container ng-scope">
	    <div className="row">
	        <div className="col-lg-12">
	            <h1 className="pb-2 mt-4 mb-2 border-bottom">Terms</h1>
	        </div>
	    </div>
	    <div className="row">
	        <div className="col-sm-12">
	            
	            <p>Lid est laborum dolo rumes fugats untras. Etharums ser quidem rerum facilis dolores nemis omnis fugats
	                vitaes nemo minima rerums unsers sadips amets. Sed ut perspiciatis unde omnis iste natus error sit
	                voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
	                veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
	        </div>
	    </div>
	</div>

)

export default TermsContent
